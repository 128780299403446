<template>
	<div class="box">
		<div class="null-page" v-show="yes"></div>
		
		<el-card class="box-card order-list">
			<div slot="header" class="clearfix"><span>{{$lang('my_order')}}</span></div>

			<el-tabs v-model="orderStatus" @tab-click="handleClick">
				<el-tab-pane :label="$lang('all_orders')" name="all"></el-tab-pane>
				<el-tab-pane :label="$lang('pending_payment')" name="waitpay"></el-tab-pane>
				<el-tab-pane :label="$lang('delivered')" name="waitsend"></el-tab-pane>
				<el-tab-pane :label="$lang('receipt')" name="waitconfirm"></el-tab-pane>
				<el-tab-pane :label="$lang('comment')" name="waitrate"></el-tab-pane>
			</el-tabs>

			<div v-loading="loading">
				<nav>
					<li>{{$lang('product_info')}}</li>
					<li>{{$lang('unit_price')}}</li>
					<li>{{$lang('quantity')}}</li>
					<li>{{$lang('payment')}}</li>
					<li>{{$lang('order_status')}}</li>
					<li>{{$lang('operation')}}</li>
				</nav>

				<div class="list" v-if="orderList.length > 0">
					<div class="item" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
						<div class="head">
							<span class="create-time">{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
							<span class="order-no">{{$lang('order_number')}}：{{ orderItem.order_no }}</span>
							<!-- <router-link :to="'/shop-' + orderItem.site_id" target="_blank">{{ orderItem.site_name }}</router-link> -->
							<span class="order-type">{{ orderItem.order_type_name }}</span>
						</div>
						<ul v-for="(goodsItem, goodsIndex) in orderItem.order_goods" :key="goodsIndex">
							<li>
								<div class="img-wrap" @click="$router.pushToTab('/product-' + goodsItem.goods_id + '.html')">
									<img :src="$img(goodsItem.sku_image, { size: 'mid' })" @error="imageError(orderIndex, goodsIndex)" />
								</div>
								<div class="info-wrap" @click="$router.pushToTab('/product-' + goodsItem.goods_id + '.html')">
									<h5>{{ goodsItem.sku_name }}</h5>
									<!-- <span>规格：规格值</span> -->
								</div>
								<div class="order-time" v-if="orderItem.order_status == 0">
									<img src="../../assets/images/order_time.png" style="width: 15px;height: 15px;margin-right: 6px;" />距离订单自动关闭，剩余
									<count-down
										style="color: #f00;margin-left: 10px;"
										class="count-down"
										v-on:start_callback="countDownS_cb()"
										v-on:end_callback="countDownE_cb()"
										:currentTime="orderItem.currentTime"
										:startTime="orderItem.startTime"
										:endTime="orderItem.endTime"
										:dayTxt="':'"
										:hourTxt="':'"
										:minutesTxt="':'"
										:secondsTxt="''"
									></count-down>
								</div>
							</li>
							<li>
								<span>{{ siteInfo.price_unit }}{{ goodsItem.price }}</span>
							</li>
							<li>
								<span>{{ goodsItem.num }}</span>
							</li>
							<li>
								<span>{{ siteInfo.price_unit }}{{ orderItem.pay_money }}</span>
							</li>
							<template v-if="goodsIndex == 0">
								<li>
									<span class="ns-text-color">{{ orderItem.order_status_name }}</span>
									<el-link :underline="false" @click="orderDetail(orderItem)">{{$lang('order_details')}}</el-link>
								</li>
								<li>
									<template v-if="orderItem.action.length > 0">
										<el-button type="primary" size="mini" plain v-if="orderItem.is_evaluate == 1" @click="operation('memberOrderEvaluation', orderItem)">
											<template v-if="orderItem.is_review == 0">
												{{$lang('comment')}}
											</template>
											<template v-else-if="orderItem.is_review == 1">
												{{$lang('review')}}
											</template>
										</el-button>

										<el-button
											type="primary"
											size="mini"
											:plain="operationItem.action == 'orderPay' ? false : true"
											v-for="(operationItem, operationIndex) in orderItem.action"
											:key="operationIndex"
											@click="operation(operationItem.action, orderItem)"
										>
											{{ operationItem.title }}
										</el-button>
									</template>

									<template class="order-operation" v-else-if="orderItem.action.length == 0 && orderItem.is_evaluate == 1">
										<el-button type="primary" size="mini" plain v-if="orderItem.is_evaluate == 1" @click="operation('memberOrderEvaluation', orderItem)">
											<template v-if="orderItem.is_review == 0">
												{{$lang('comment')}}
											</template>
											<template v-else-if="orderItem.is_review == 1">
												{{$lang('review')}}
											</template>
										</el-button>
									</template>
								</li>
							</template>
						</ul>
					</div>
				</div>
				<div v-else-if="!loading && orderList.length == 0" class="empty-wrap">{{$lang('no_order')}}</div>
			</div>
			<div class="pager">
				<el-pagination 
					background 
					:pager-count="5" 
					:total="total" 
					:prev-text="$lang('prevtext')" 
					:next-text="$lang('nexttext')" 
					:current-page.sync="currentPage" 
					:page-size.sync="pageSize" 
					@size-change="handlePageSizeChange" 
					@current-change="handleCurrentPageChange" 
					hide-on-single-page
				></el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex';
	import { apiOrderList } from '@/api/order/order';
	import orderMethod from '@/utils/orderMethod';
	import CountDown from "vue2-countdown"
	export default {
		name: 'order_list',
		components: {
			CountDown
		},
	data: () => {
		return {
			orderStatus: 'all',
			loading: true,
			orderList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0,
			yes: true
		};
	},
	mixins: [orderMethod],
	created() {
		this.orderStatus = this.$route.query.status || 'all';
		this.getOrderList();
	},
	computed: {
		...mapGetters(['defaultGoodsImage', "siteInfo"])
	},
	mounted() {
		let self = this;
		setTimeout(function() {
			self.yes = false
		}, 300)
	},
	methods: {
			countDownE_cb() {},
		handleClick(tab, event) {
			this.currentPage = 1;
			this.orderStatus = tab.name;
			this.refresh();
		},
		getOrderList() {
			apiOrderList({
				page: this.currentPage,
				page_size: this.pageSize,
				order_status: this.orderStatus
			}).then(res => {
				let list = [];
					let auto_close = 0
					if (res.code == 0 && res.data) {
						auto_close = res.data.auto_close
						let date = (Date.parse(new Date()))/1000
						for(let i=0;i<res.data.list.length;i++){
							res.data.list[i].currentTime = date
							res.data.list[i].startTime = date
							res.data.list[i].endTime = res.data.list[i].create_time + auto_close
						}
					list = res.data.list;
					this.total = res.data.count;
				}
				this.orderList = list;
				this.loading = false;
			}).catch(res => {
				this.loading = false;
			});
		},
		handlePageSizeChange(size) {
			this.pageSize = size;
			this.refresh();
		},
		handleCurrentPageChange(page) {
			this.currentPage = page;
			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.getOrderList();
		},
		operation(action, orderData) {
			let index = this.status;
			switch (action) {
				case 'orderPay': // 支付
					this.orderPay(orderData);
					break;
				case 'orderClose': //关闭
					this.orderClose(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'memberTakeDelivery': //收货
					this.orderDelivery(orderData.order_id, () => {
						this.refresh();
					});
					break;
				case 'trace': //查看物流
					this.$router.push({ path: '/member/logistics', query: { order_id: orderData.order_id } });
					break;
				case 'memberOrderEvaluation': //评价
					this.$router.pushToTab({ path: '/evaluate', query: { order_goods_id: orderData.order_goods_id } });
					break;
				
			}
		},
		orderDetail(data) {
			switch (parseInt(data.order_type)) {
				case 2:
					// 自提订单
					this.$router.push({ path: '/member/order_detail_pickup', query: { order_id: data.order_id } });
					break;
				case 3:
					// 本地配送订单
					this.$router.push({ path: '/member/order_detail_local_delivery', query: { order_id: data.order_id } });
					break;
				case 4:
					// 虚拟订单
					this.$router.push({ path: '/member/order_detail_virtual', query: { order_id: data.order_id } });
					break;
				default:
					this.$router.push({ path: '/member/order_detail', query: { order_id: data.order_id } });
					break;
			}
		},
		imageError(orderIndex, goodsIndex) {
			this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>
<style lang="scss" scoped>
.box {
	width: 100%;
	position: relative;
}

.null-page {
	width: 100%;
	height: 730px;
	background-color: #FFFFFF;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 9;
}

.order-list {
	nav {
		overflow: hidden;
		padding: 10px 0;
		background: #fff;
		margin-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		li {
			float: left;
			&:nth-child(1) {
				width: 45%;
			}
			&:nth-child(2) {
				width: 10%;
			}
			&:nth-child(3) {
				width: 10%;
			}
			&:nth-child(4) {
				width: 10%;
			}
			&:nth-child(5) {
				width: 15%;
			}
			&:nth-child(6) {
				width: 10%;
			}
		}
	}
	.list {
		.item {
			margin-bottom: 20px;
			border: 1px solid #eeeeee;
			border-top: 0;
			.head {
				padding: 8px 10px;
				background: #f7f7f7;
				font-size: 12px;

				.create-time {
					margin-right: 10px;
				}

				border-bottom: 1px solid #eeeeee;
				a {
					margin: 0 10px 0 20px;
				}

					.order-type {
						margin-left: 30px;
					}
				}
				.order-time {
					display: flex;
					align-items: center;
					font-size: 10px;
				}
			}

		ul {
			background-color: #fff;
			padding: 10px;
			overflow: hidden;
			li {
				float: left;
				line-height: 60px;
				&:nth-child(1) {
					width: 45%;
					line-height: inherit;
					.img-wrap {
						width: 60px;
						height: 60px;
						float: left;
						margin-right: 10px;
						cursor: pointer;
					}
					.info-wrap {
						margin-left: 70px;
						h5 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
							cursor: pointer;
							&:hover {
								color: $base-color;
							}
						}
						h6 {
							font-weight: normal;
							font-size: $ns-font-size-base;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							margin-right: 10px;
							display: inline-block;
						}
						span {
							font-size: $ns-font-size-sm;
							color: #9a9a9a;
						}
					}
				}
				&:nth-child(2) {
					width: 10%;
				}
				&:nth-child(3) {
					width: 10%;
				}
				&:nth-child(4) {
					width: 10%;
				}
				&:nth-child(5) {
					width: 15%;
					line-height: 30px;
					a {
						display: block;
					}
				}
				&:nth-child(6) {
					width: 10%;
					line-height: initial;
					button {
						margin-left: 0;
						margin-bottom: 10px;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
	}
	.empty-wrap {
		text-align: center;
		padding: 10px;
	}
}
.present-label {
	padding: 0 3px;
	background-color: #ff8143;
	display: inline-block;
	color: #fff;
	font-size: 12px;
	border-radius: 2px;
}
</style>
